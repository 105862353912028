<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="bg-img-hero position-relative overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <img class="img-fluid mb-5" src="@/assets/img/products/fabrixai-logo.svg" style="max-width:15rem">
          <h1 class="mb-3">The Next-Gen AI Agents Development Platform</h1>
          <p class="lead">Leverage the power of Generative AI to dramatically boost the construction time for your AI applications and agents.</p>
          <a class="btn btn-primary transition-3d-hover mt-3" href="https://www.fabrixai.com/" target="_blank">Learn More <i class="fas fa-external-link-alt ml-1" /></a>
        </div>
        <div class="position-relative w-90 w-md-50 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/svg/illustrations/ai-agent-2.svg" alt="FabriXAI">
          </div>
        </div>
        <!-- <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/fiberconnect/fiber-hero-img.png" alt="FiberConnect Portals">
          </div>
        </div> -->
      </div>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2 space-lg-3">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">The Ultimate AI Management Platform for Your Team</h2>
            <p>Say goodbye to continuous copy and pasting. With FabriXAI, you can easily create prompts using variables, making prompt creation simpler and more efficient. No more spreadsheets or manual tracking.</p>
            <h5 class="mb-3">Popular Features</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Prompt Library for Customization
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Collaborate with your team seamlessly
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Manage costs and monitor token usage for team member
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/prompt-builder.jpg" alt="The Ultimate AI Management Platform for Your Team">
          </div>
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Integrate with Different Models</h2>
            <p>FabriXAI offers seamless integration with a variety of LLMs, providing you with the flexibility to choose the model that best suits your needs. Whether you prefer OpenAI, Anthropic, Azure, or other models, FabriXAI has you covered.</p>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/llm.jpg" alt="Integrate with Different Models">
          </div>
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="row align-items-lg-center">
        <div class="col-lg-6 col-xl-6 mb-6 mb-lg-0">
          <h2 class="mb-3">Build Your Own AI Agents</h2>
          <p>Take prompt management to the next level by building your own AI agents with FabriXAI. Our platform empowers you to create custom AI agents that can interact with prompts, enabling advanced automation and intelligent workflows. Unleash the full potential of AI in your team's operations.</p>

        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/ai-agent.jpg" alt="Build Your Own AI Agents">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="bg-img-hero position-relative overflow-hidden text-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Boost Your Workflow with AI Agents</h2>
          <p>Interested in accelerating your processes? Explore the powerful features of FabriXAI now!</p>
        </div>
        <a class="btn btn-primary transition-3d-hover" href="https://www.fabrixai.com/" target="_blank">Explore FabriXAI <i class="fas fa-external-link-alt ml-1" /></a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Management Platform</h3>
              <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Leading API Community</h3>
              <p><strong>OpenAPIHub</strong> is the leading API community with a powerful API directory and ecosystem.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Connectivity for Financial Services</h3>
              <p><strong>FiberConnect</strong> uses Open API to boost SME & Startup efficiency with QR Code payments.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>AI Agents for Education</h3>
              <p><strong>TapTab</strong> is the AI agents designed for the education sector.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->

  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'FabriXAI',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'FabriXAI | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/fabrixai' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'FabriXAI | Products | beNovelty' },
        { property: 'og:description', content: 'Unlock the power of generative AI with FabriXAI—the Next-Gen AI Agents Development Platform.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/fabrixai' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

